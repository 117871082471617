@font-face {
  font-family: "Inter Bold";
  src: url(./assets/fonts/Inter/Inter-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Inter Black";
  src: url(./assets/fonts/Inter/Inter-Black.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Inter ExtraBold";
  src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Inter ExtraLight";
  src: url(./assets/fonts/Inter/Inter-ExtraLight.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Inter Light";
  src: url(./assets/fonts/Inter/Inter-Light.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Inter Medium";
  src: url(./assets/fonts/Inter/Inter-Medium.ttf);
}

@font-face {
  font-family: "Inter Regular";
  src: url(./assets/fonts/Inter/Inter-Regular.ttf);
}

@font-face {
  font-family: "Inter SemiBold";
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: "Inter Thin";
  src: url(./assets/fonts/Inter/Inter-Thin.ttf);
}

@font-face {
  font-family: "Lato Black";
  src: url(./assets/fonts/Lato/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato BlackItalic";
  src: url(./assets/fonts/Lato/Lato-BlackItalic.ttf);
}

@font-face {
  font-family: "Lato Bold";
  src: url(./assets/fonts/Lato/Lato-Bold.ttf);
}

@font-face {
  font-family: "Lato BoldItalic";
  src: url(./assets/fonts/Lato/Lato-BoldItalic.ttf);
}

@font-face {
  font-family: "Lato Italic";
  src: url(./assets/fonts/Lato/Lato-Italic.ttf);
}

@font-face {
  font-family: "Lato Light";
  src: url(./assets/fonts/Lato/Lato-Light.ttf);
}

@font-face {
  font-family: "Lato LightItalic";
  src: url(./assets/fonts/Lato/Lato-LightItalic.ttf);
}

@font-face {
  font-family: "Lato Regular";
  src: url(./assets/fonts/Lato/Lato-Regular.ttf);
}

@font-face {
  font-family: "Lato Thin";
  src: url(./assets/fonts/Lato/Lato-Thin.ttf);
}

@font-face {
  font-family: "Lato ThinItalic";
  src: url(./assets/fonts/Lato/Lato-ThinItalic.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main.css-mbn7gz {
  margin-top: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active-row {
  background-color: #ffc0cb;
}
.flex_between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.flex_center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.striped {
  background-color: #f5f5f5;
}

/* @media(max-width:2880px) {
  .zoom-grid1{
    zoom:100%
  }
  .zoom-grid2{
      zoom:66%;
  }
  .analytics-page .heading4 {
    font-size: 24px;
  }
  .analytics-page .MuiTypography-h5 {
    font-size: 18px;
  }
  .analytics-page .MuiTypography-h6 {
    font-size: 18px;
  }
  .analytics-page .MuiDataGrid-columnHeaderTitle .MuiBox-root{
    font-size: 18px;
  }
  .analytics-page .MuiDataGrid-cell{
    font-size: 18px;
  }
  .analytics-page .MuiDataGrid-cell button{
    font-size: 18px;
  }
}

@media(max-width:1366px){
  .zoom-grid1{
    zoom:65%
  }
  .zoom-grid2{
      zoom:41%
  }
  .analytics-page .heading4{
    font-size: 28px !important;
  }
  .analytics-page .heading5 {
    font-size: 22px !important;
  }
  .analytics-page .MuiTypography-h6 {
    font-size: 24px !important;
  }
  .analytics-page .MuiDataGrid-columnHeaderTitle .MuiBox-root{
    font-size: 21px !important;
  }
  .analytics-page .MuiDataGrid-cell{
    font-size: 20px;
  }
  .analytics-page .MuiDataGrid-cell button{
    font-size: 20px;
  }
} */
/* .analytics-page{
      height: calc(100vh - 68px);
    overflow: hidden;
} */
/* .analytics-page{
  max-height:calc(100vh - 64px)
}
.analytics-page .MuiGrid-item{
    padding-top: 7px !important;
}
.heading5{
  font-size: 12px;
} */

.flex_col {
  display: flex;
  flex-direction: column;
}
.w_25 {
  width: 25%;
}
.w_33 {
  width: 33%;
}
.w_50 {
  width: 50%;
}
.w_100 {
  width: 100%;
}
.h_25 {
  height: 25%;
}
.h_33 {
  height: 33%;
}
.h_50 {
  height: 50%;
}
.h_100 {
  height: 100%;
}
::selection {
  background-color: #563ad5;
  color: white;
}
